import React from "react";
import RecruiterTable from "../../components/common/recruiterTable";
import "./users.css";

export default function Recruiters() {
  return (
    <>
      <div className="user-top-container">
        <h3>Manage Recruiters</h3>
      </div>
        <RecruiterTable />
    </>
  )
}
