import { notification } from "antd";

export function openNotification(description, code) {
  notification.info({
    message: code === 200 ? "Success" : "Error" ,
    description,
    placement: "topRight",
    className: code === 200 ? 'success-notification' : 'error-notification',
  });
}
