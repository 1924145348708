import React, { useEffect, useState, useRef } from "react";
import {
  Tabs,
  Select,
  Table,
  Input,
  Button,
  Modal,
  Form,
  Tag,
  Popconfirm,
  Spin,
} from "antd";
import { FormOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  getCacheAdmin,
  getDynamicDropdown,
  updateDropdown,
  addDropdown,
  deleteDropdown,
} from "../../services/Services";
import { getCachedTypes } from "../../services/ManageCachedContent";
import "./styles.css";
import manageTables from "../../services/ManageTables";
import { openNotification } from "../../services/NotificationService";

export default function Dropdown() {
  const [activeTab, setActiveTab] = useState("1");
  const [typeDropdown, setTypeDropdown] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apiData, setApiData] = useState([]);
  const [dependentApiData, setDependentApiData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [originalDependData, setOriginalDependData] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [dependentData, setDependentData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [initialValues, setInitialValues] = useState({
    key: null,
    title: null,
    sub_headings: null,
  });
  const [inputVisible, setInputVisible] = useState(false);
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState([]);
  const [form] = Form.useForm();
  const { TabPane } = Tabs;
  const [pagination, setPagination] = useState({ current: 1 });

  const languageDropdown = [
    { label: "English", value: "eng" },
    { label: "Simplified Chinese", value: "zh-ch" },
    { label: "Traditional Chinese", value: "zh-cht" },
  ];

  useEffect(() => {
    const fetchInitData = async () => {
      try {
        const cachedContent = await getCacheAdmin();
        const formattedTypes = getCachedTypes(
          cachedContent?.data?.DEFAULT_DROPDOWN_TYPES
        );
        setTypeDropdown(formattedTypes);
        setSelectedType(formattedTypes[0]?.value); // set initial selected value
      } catch (err) {
        console.error("Error: ", err);
      }
    };

    fetchInitData();
  }, []);

  useEffect(() => {
    if (selectedType !== null) {
      handleChange(selectedType);
    }
    // eslint-disable-next-line
  }, [selectedType]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const handleChange = async (value) => {
    setLoading(true);
    try {
      const response = await getDynamicDropdown(value);
      let formattedData = manageTables(response.data?.data, "dynamicDD");
      setData(formattedData?.data);
      setApiData(response);
      setOriginalData(formattedData?.data);
      setSelectedType(value); // update selectedType state
      fetchData();
    } catch (error) {
      console.error("Error: ", error);
      // Handle the error as needed (e.g. show an error message, set default values, etc.)
    } finally {
      setLoading(false);
    }
  };

  const getDataAfterAction = async () => {
    setLoading(true);
    if (activeTab === "1") {
      try {
        const response = await getDynamicDropdown(selectedType);
        let formattedData = manageTables(response.data?.data, "dynamicDD");
        setData(formattedData?.data);
        setApiData(response);
        setOriginalData(formattedData?.data);
      } catch (error) {
        console.error("Error: ", error);
        // Handle the error as needed (e.g. show an error message, set default values, etc.)
      } finally {
        setLoading(false);
      }
    } else {
      fetchData();
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const dependentTblData = await getDynamicDropdown("job_function");
      setDependentApiData(dependentTblData.data.data);
      let formattedData = manageTables(
        dependentTblData.data?.data,
        "dynamicDD"
      );
      setOriginalDependData(formattedData.data);
      setDependentData(formattedData.data);
    } catch (err) {
      console.error("Error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = async (key) => {
    setActiveTab(key);
    setSearchQuery("");
    if (key === "2") {
      setSelectedType("job_function");
    } else {
      setSelectedType(typeDropdown[0]?.value);
    }
  };

  let handleEdit = (itemId) => {
    setIsAddModal(false);
    const seletedDataset =
      activeTab === "1" ? apiData.data.data : dependentApiData;
    const selectItem = seletedDataset?.filter((item) => item._id === itemId);
    const newFormValues = {
      key: selectItem[0]._id,
      title: selectItem[0]._source.item.title,
      sub_headings: selectItem[0]._source.item.sub_headings,
    };
    setTags(selectItem[0]._source.item.sub_headings);
    form.setFieldsValue(newFormValues);
    setInitialValues(newFormValues);
    showModal();
  };

  const handleDelete = async (itemId) => {
    try {
      const response = await deleteDropdown(itemId);
      openNotification("Successfully deleted", response?.status);
      setTimeout(function () {
        getDataAfterAction();
      }, 1000);
    } catch (err) {
      openNotification(
        err?.response?.data?.error?.message,
        err?.response?.status
      );
    }
  };

  const handleAdd = () => {
    setIsAddModal(true);
    setInitialValues({
      key: null,
      title: null,
      sub_headings: null,
    });
    setTags([]);
    form.resetFields();
    showModal();
  };

  const handleSearch = (query, tab) => {
    setSearchQuery(query);
    if (tab === "tab1") {
      const searchData = originalData.filter((item) =>
        item.title.toLowerCase().includes(query.toLowerCase())
      );
      setData(searchData);
    } else {
      const searchData = originalDependData.filter((item) =>
        item.title.toLowerCase().includes(query.toLowerCase())
      );
      setDependentData(searchData);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = async (values, tags) => {
    try {
      const response =
        values.key === null
          ? await addDropdown(selectedType, values, tags)
          : await updateDropdown(selectedType, values, tags);
      openNotification(
        "Your Operations has been successfully completed",
        response?.status
      );
      setIsModalOpen(false);
      setTimeout(function () {
        getDataAfterAction();
      }, 1000);
    } catch (err) {
      openNotification(
        err?.response?.data?.error?.message,
        err?.response?.status
      );
    }
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const showInput = () => {
    setInputVisible(true);
  };

  const columns = [
    {
      title: "Description",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <FormOutlined onClick={() => handleEdit(record.key)} />
          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <DeleteOutlined />
          </Popconfirm>
        </>
      ),
    },
  ];

  function callback(key) {
    handleTabChange(key);
    setPagination({ current: 1 });
  }
  const handlePaginationChange = (pagination) => {
    setPagination(pagination);
  };

  const handleRemoveSubheading = (removedIndex) => {
    const updatedTags = tags.filter((_, index) => index !== removedIndex);
    setTags(updatedTags);
  };

  return (
    <>
      <h3>Manage Dynamic Dropdown</h3>

      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="Independent Dropdown" key="1">
          <>
            <div className="dropdown-container">
              <div className="dropdown-wrapper">
                <label>Type</label>
                <Select
                  className="filter-dropdown"
                  options={typeDropdown}
                  value={selectedType}
                  onChange={(selectedOption) => handleChange(selectedOption)}
                ></Select>
              </div>
            </div>
            <div className="action-container">
              <Input
                placeholder="Search By Title"
                onChange={(e) => handleSearch(e.target.value, "tab1")}
                value={searchQuery}
                style={{ width: 300 }}
              />
              <Button type="primary" onClick={handleAdd}>
                Add
              </Button>
            </div>
            <div className="tbl-container">
              <>
                {loading ? (
                  <Spin size="large" />
                ) : data.length > 0 ? (
                  <Table
                    dataSource={data}
                    columns={columns}
                    pagination={pagination}
                    onChange={handlePaginationChange}
                  />
                ) : (
                  <h1>No results found</h1>
                )}
              </>
            </div>
          </>
        </TabPane>

        <TabPane tab="Dependent Dropdown" key="2">
          <>
            <div className="dropdown-container">
              <div className="dropdown-wrapper">
                <label>Type</label>
                <span className="selected-option">Job Function</span>
              </div>
            </div>
            <div className="action-container">
              <Input
                placeholder="Search By Title"
                onChange={(e) => handleSearch(e.target.value, "tab2")}
                value={searchQuery}
                style={{ width: 300 }}
              />
              <Button type="primary" onClick={handleAdd}>
                Add
              </Button>
            </div>
            <div className="tbl-container">
              {dependentData?.length > 0 ? (
                <Table
                  dataSource={dependentData}
                  columns={columns}
                  pagination={pagination}
                  onChange={handlePaginationChange}
                />
              ) : (
                <h3>No results found</h3>
              )}
            </div>
          </>
        </TabPane>
      </Tabs>

      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Form
          onFinish={(values) => handleSubmit(values, tags)}
          form={form}
          initialValues={initialValues}
          className="edit-dropdown-form"
        >
          <Form.Item name="key" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="title"
            label="Item Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="lang"
            label="Select language"
            rules={[{ required: true }]}
            initialValue={languageDropdown[0].value}
            hidden={!isAddModal}
          >
            <Select options={languageDropdown}></Select>
          </Form.Item>

          {activeTab === "2" && (
            <Form.Item
              name="sub_headings"
              label="Sub headings"
              className="tag-container"
            >
              <>
                {tags.map((heading, index) => (
                  <Tag
                    key={index}
                    closable={true}
                    onClose={() => handleRemoveSubheading(index)}
                  >
                    {heading}
                  </Tag>
                ))}
                {inputVisible ? (
                  <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                  />
                ) : (
                  <Tag className="add-tag" onClick={showInput}>
                    <PlusOutlined /> Add sub heading
                  </Tag>
                )}
              </>
            </Form.Item>
          )}
          <button type="submit">
            {initialValues.key === null &&
            initialValues.title === null &&
            initialValues.sub_headings === null
              ? "Add"
              : "Update"}
          </button>
        </Form>
      </Modal>
    </>
  );
}
