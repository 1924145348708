import { Tag } from "antd";
import { FormOutlined, DeleteOutlined } from "@ant-design/icons";

export default function manageTables(tableData, tableName) {
  let selectedData;
  switch (tableName) {
    case "membershipPlan":
      selectedData = getMembershipPlanData(tableData);
      break;
    case "admin":
      selectedData = getAdminData(tableData);
      break;
    case "recruiters":
      selectedData = getRecruitersData(tableData);
      break;
    case "subscription":
      selectedData = getSubscriptionData(tableData);
      break;
    case "invoiceTable":
      selectedData = getInvoiceData(tableData);
      break;
    case "serviceInvoice":
      selectedData = getServiceInvoiceData(tableData);
      break;
    case "branding":
      selectedData = getBrandingData(tableData);
      break;
    case "dynamicDD":
      selectedData = getDynamicDropdownData(tableData);
      break;
    default:
      selectedData = null; // or handle the default case accordingly
      break;
  }
  return selectedData;
}

export function getMembershipPlanData(tableData) {
  let data = [];
  for (let i = 0; i < tableData?.length; i++) {
    data.push({
      key: tableData[i].id,
      title: tableData[i].title_eng,
      cost: tableData[i].cost,
      token_amount: tableData[i].token_amount,
      status: (
        <Tag color={tableData[i].status === true ? "green" : "red"}>
          {tableData[i].status ? "Active" : "Inactive"}
        </Tag>
      ),
      action: (
        <>
          <FormOutlined />
          <DeleteOutlined />
        </>
      ),
    });
  }
  return { data };
}

export function getRecruitersData(tableData) {
  let data = [];
  for (let i = 0; i < tableData?.length; i++) {
    data.push({
      key: tableData[i].id,
      name: tableData[i].firstName + " " + tableData[i].lastName,
      email: tableData[i].email,
      status: (
        <Tag color={tableData[i].admin_active === true ? "green" : "red"}>
          {tableData[i].admin_active ? "Active" : "Inactive"}
        </Tag>
      ),
      action: (
        <>
          <FormOutlined />
          <DeleteOutlined />
        </>
      ),
    });
  }
  return { data };
}

export function getAdminData(tableData) {
  let data = [];
  for (let i = 0; i < tableData?.length; i++) {
    data.push({
      key: tableData[i].id,
      name: tableData[i].firstName + " " + tableData[i].lastName,
      email: tableData[i].email,
      status: (
        <Tag color={tableData[i].active === true ? "green" : "red"}>
          {tableData[i].active ? "Active" : "Inactive"}
        </Tag>
      ),
      action: (
        <>
          <FormOutlined />
          <DeleteOutlined />
        </>
      ),
    });
  }
  return { data };
}

export function getSubscriptionData(tableData) {
  let data = [];
  for (let i = 0; i < tableData?.length; i++) {
    data.push({
      key: tableData[i].id,
      feature: tableData[i].feature,
      token_needed: tableData[i].token_needed,
      additional_info_eng: tableData[i].additional_info_eng,
      additional_info_zh_ch: tableData[i].additional_info_zh_ch,
      additional_info_zh_cht: tableData[i].additional_info_zh_cht,
    });
  }
  return { data };
}

export function getInvoiceData(tableData) {
  let data = [];
  for (let i = 0; i < tableData?.length; i++) {
    data.push({
      key: tableData[i]._id,
      id: tableData[i]._id,
      createdAt: getFormattedDate(tableData[i]._source.createdAt),
      due_date: getFormattedDate(tableData[i]?._source?.invoice?.due_date),
      company_name: tableData[i]._source.recruiter_company_name,
      recruiter_email: tableData[i]._source.recruiter_email,
      payment_method: tableData[i]._source.payment_method,
      purpose: tableData[i]._source.purpose,
      amount: tableData[i]._source.amount,
      currency: tableData[i]._source.currency,
      status: tableData[i]._source.status,
    });
  }
  return { data };
}

export function getServiceInvoiceData(tableData) {
  let data = [];
  for (let i = 0; i < tableData?.length; i++) {
    data.push({
      key: tableData[i]._id,
      invoice_id: tableData[i]._id,
      invoice_date: getFormattedDate(tableData[i]._source.createdAt),
      due_date: getFormattedDate(tableData[i]._source.due_date),
      company_name: tableData[i]._source.company_name,
      recruiter_email: tableData[i]._source.recruiter_email,
      generated_by: tableData[i]._source.generated_by,
      amount: tableData[i]._source.amount,
      status: tableData[i]._source.status,
      action: (
        <>
          <DeleteOutlined />
        </>
      ),
    });
  }
  return { data };
}

export function getBrandingData(tableData) {
  let data = [];
  for (let i = 0; i < tableData?.length; i++) {
    data.push({
      key: tableData[i]._id,
      title: tableData[i]._source.title,
      type: <Tag color="blue">{tableData[i]._source.type}</Tag>,
      publish_date: getFormattedDate(tableData[i]._source.publish_date),
      exp_date: getFormattedDate(tableData[i]._source.exp_date),
      status: tableData[i]._source.status,
      reason: tableData[i]._source.deactivated_reason,
      action: (
        <>
          <FormOutlined />
        </>
      ),
    });
  }
  return { data };
}

export function getFormattedDate(dateString, isIncludeTime = false) {
  const initDate = new Date(dateString);
  if(isNaN(initDate)) return `n/a`;

  const day = initDate.getDate().toString().padStart(2, "0");
  const month = (initDate.getMonth() + 1).toString().padStart(2, "0");
  const year = initDate.getFullYear().toString();
  const readableTime = initDate.toLocaleTimeString();
  
  if(isIncludeTime) {
    return `${day}-${month}-${year} | ${readableTime}`;
  }
  return `${day}-${month}-${year}`;
}

export function getDynamicDropdownData(tableData) {
  let data = [];
  for (let i = 0; i < tableData?.length; i++) {
    data.push({
      key: tableData[i]._id,
      title: tableData[i]._source.item.title,
      action: (
        <>
          <FormOutlined />
        </>
      ),
    });
  }
  return { data };
}
