import { Table, Modal, Input, Select, Popconfirm, Button, Form, Spin } from "antd";
import { useState, useEffect } from "react";
import manageTables from "../../../services/ManageTables";
import { FormOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  getAdminUsers,
  updateAdmin,
  deleteAdmin,
  createAdmin,
} from "../../../services/Services";
import { openNotification } from "../../../services/NotificationService";
import "./styles.css";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 14 },
};

const AdminTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateItem, setUpdateItem] = useState({
    id: null,
    status: null,
  });
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getAdminUsers();
      let formattedData = manageTables(response.data?.rows, "admin");
      setOriginalData(formattedData.data);
      setData(formattedData.data);
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    const searchData = originalData.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
    searchData ? setData(searchData) : setData(originalData);
  };

  let handleEdit = (itemId) => {
    const selectItemStatus = data.filter((item) => item.key === itemId);
    form2.setFieldsValue({ status: selectItemStatus[0].status.props.children === "Active" ? true : false });
    setUpdateItem({
      id: itemId,
      status:
        selectItemStatus[0].status.props.children === "Active" ? true : false,
    });
    setModalVisible(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text) => <a href={`mailto:${text}`}>{text}</a>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div>
          <FormOutlined onClick={() => handleEdit(record.key)} />
          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleDelete = async (itemId) => {
    try {
      const response = await deleteAdmin(itemId);
      openNotification("Successfully deleted", response?.status);
      fetchData();
    } catch (err) {
      openNotification(
        err?.response?.data?.error?.message,
        err?.response?.status
      );
    }
  };

  function handleChange(value) {
    setUpdateItem({ ...updateItem, status: value });
  }

  const handleSubmit = async (event) => {
    try {
      const response = await updateAdmin(updateItem);
      openNotification(
        "Your Operations has been successfully completed",
        response?.status
      );
      fetchData();
      setModalVisible(false);
    } catch (err) {
      openNotification(
        err?.response?.data?.error?.message,
        err?.response?.status
      );
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };


  const onFinish = async (values) => {
    try {
      const response = await createAdmin(values);
      openNotification(
        "Your Operations has been successfully completed",
        response?.status
      );
      fetchData();
      setIsModalOpen(false);
      form.resetFields();
    } catch (err) {
      openNotification(
        err?.response?.data?.error?.message,
        err?.response?.status
      );
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // Regular expression pattern to validate email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Input
          placeholder="Search By Name"
          onChange={handleSearch}
          style={{ width: 300 }}
        />
      </div>

      <Button type="primary" onClick={showModal}>
        Add
      </Button>

      <>
        {loading ? (
          <Spin size="large" />
        ) : data.length > 0 ? (
          <Table dataSource={data} columns={columns} pagination={true} />
        ) : (
          <h1>No results found</h1>
        )}
      </>

      <Modal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Form
          onFinish={handleSubmit}
          className="edit-status-form"
          form={form2}
          name="update"
        >
          <Form.Item
            name="status"
            label="Status"
            initialValue={updateItem.status}
          >
            <Select onChange={handleChange}>
              <Option value={true}>Active</Option>
              <Option value={false}>Inactive</Option>
            </Select>
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form>
      </Modal>

      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Form
          {...layout}
          form={form}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              { required: true, message: "Please input your first name!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              { required: true, message: "Please input your last name!" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
              {
                pattern: emailRegex,
                message: "Please enter a valid email address!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
              {
                min: 8,
                message: "Password must be at least 8 characters long!",
              },
              {
                pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                message:
                  "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your new password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AdminTable;
