import {
  Table,
  Modal,
  Input,
  Select,
  Popconfirm,
  Button,
  Form,
  InputNumber,
  Col,
  Row,
  Spin
} from "antd";
import { useState, useEffect } from "react";
import manageTables from "../../../services/ManageTables";
import { FormOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import {
  getMembershipPlans,
  updateMembershipPlans,
  deleteMembershipPlans,
  createMembershipPlans,
  getCacheAdmin,
} from "../../../services/Services";
import { openNotification } from "../../../services/NotificationService";
import "./styles.css";

const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 18, span: 14 },
};

const MembershipTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [defaultCurrency, setDefaultCurrency] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [form] = Form.useForm();

  const [initialValues, setInitialValues] = useState({
    key: null,
    title_eng: null,
    title_zh_ch: null,
    title_zh_cht: null,
    cost: null,
    token_amount: null,
    description_eng: null,
    description_zh_ch: null,
    description_zh_cht: null,
    status: true,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getMembershipPlans();
      const cacheData = await getCacheAdmin();
      setDefaultCurrency(cacheData?.data?.DEFAULT_CURRENCY);
      let formattedData = manageTables(response.data?.data, "membershipPlan");
      setApiData(response.data.data);
      setOriginalData(formattedData.data);
      setData(formattedData.data);
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    const searchData = originalData.filter((item) =>
      item.title.toLowerCase().includes(query.toLowerCase())
    );
    searchData ? setData(searchData) : setData(originalData);
  };

  let handleEdit = (itemId) => {
    const selectItemStatus = apiData.filter((item) => item.id === itemId);
    handleSelectedRow(selectItemStatus, "edit");
    setModalVisible(true);
  };

  const handleOpen = (itemId) => {
    const selectItemStatus = apiData.filter((item) => item.id === itemId);
    handleSelectedRow(selectItemStatus, "view");
    setIsViewModalOpen(true);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: <>Cost ({defaultCurrency})</>,
      dataIndex: "cost",
      key: "cost",
      sorter: (a, b) => a.cost - b.cost,
    },
    {
      title: "Token Amount",
      dataIndex: "token_amount",
      key: "token_amount",
      sorter: (a, b) => a.token_amount - b.token_amount,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div>
          <FormOutlined onClick={() => handleEdit(record.key)} />
          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <DeleteOutlined />
          </Popconfirm>
          <EyeOutlined onClick={() => handleOpen(record.key)} />
        </div>
      ),
    },
  ];

  const handleDelete = async (itemId) => {
    try {
      const response = await deleteMembershipPlans(itemId);
      openNotification('Successfully deleted', response?.status);
      setTimeout(function () {
        fetchData();
      }, 1000);
    } catch (err) {
      openNotification(err?.response?.data?.error?.message, err?.response?.status);
    }
  };

  const handleUpdate = async (values) => {
    try {
      const response = await updateMembershipPlans(values);
      openNotification('Your Operations has been successfully completed', response?.status);
      setTimeout(function () {
        fetchData();
      }, 1000);
      setModalVisible(false);
    } catch (err) {
      openNotification(err?.response?.data?.error?.message, err?.response?.status);
    }
  };

  const showModal = () => {
    form.setFieldsValue({
      key: null,
      title_eng: null,
      title_zh_ch: null,
      title_zh_cht: null,
      cost: null,
      token_amount: null,
      description_eng: null,
      description_zh_ch: null,
      description_zh_cht: null,
      status: true,
    });
    setIsModalOpen(true);
  };

  const onFinish = async (values) => {
    try {
      const response = await createMembershipPlans(values);
      openNotification('Your Operations has been successfully completed', response?.status);
      fetchData();
      setIsModalOpen(false);
      form.resetFields();
    } catch (err) {
      openNotification(err?.response?.data?.error?.message, err?.response?.status);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  let handleSelectedRow = (selectItemStatus, type) => {
    const newFormValues = {
      key: selectItemStatus[0].id,
      title_eng: selectItemStatus[0].title_eng,
      title_zh_ch: selectItemStatus[0].title_zh_ch,
      title_zh_cht: selectItemStatus[0].title_zh_cht,
      cost: selectItemStatus[0].cost,
      token_amount: selectItemStatus[0].token_amount,
      description_eng: selectItemStatus[0].description_eng,
      description_zh_ch: selectItemStatus[0].description_zh_ch,
      description_zh_cht: selectItemStatus[0].description_zh_cht,
      status: selectItemStatus[0].status,
    };
    if (type === "edit") {
      form.setFieldsValue(newFormValues);
    }
    setInitialValues(newFormValues);
  };

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Input
          placeholder="Search By Title"
          onChange={handleSearch}
          style={{ width: 300 }}
        />
      </div>

      <Button type="primary" onClick={showModal}>
        Add
      </Button>

      <>
        {loading ? (
          <Spin size="large" />
        ) : data.length > 0 ? (
          <Table dataSource={data} columns={columns} pagination={true} />
        ) : (
          <h1>No results found</h1>
        )}
      </>

      <Modal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Form
          {...layout}
          form={form}
          name="update"
          initialValues={initialValues}
          onFinish={handleUpdate}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item name="key" hidden initialValue={initialValues.id}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Title ENG"
            name="title_eng"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Simplified Chinese" name="title_zh_ch" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Traditional Chinese" name="title_zh_cht" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label={`Cost (${defaultCurrency})`} name="cost" rules={[{ required: true }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Token Amount"
            name="token_amount"
            rules={[{ required: true }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item label="Status" name="status" rules={[{ required: true }]}>
            <Select>
              <Option value={true}>Active</Option>
              <Option value={false}>Inactive</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Description ENG" name="description_eng">
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item label="Simplified Chinese" name="description_zh_ch">
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item label="Traditional Chinese" name="description_zh_cht">
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Form
          {...layout}
          form={form}
          name="create"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Title ENG"
            name="title_eng"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Simplified Chinese" name="title_zh_ch" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Traditional Chinese" name="title_zh_cht" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label={`Cost (${defaultCurrency})`} name="cost" rules={[{ required: true }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Token Amount"
            name="token_amount"
            rules={[{ required: true }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item label="Status" name="status" rules={[{ required: true }]}>
            <Select>
              <Option value={true}>Active</Option>
              <Option value={false}>Inactive</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Description ENG" name="description_eng">
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item label="Simplified Chinese" name="description_zh_ch">
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item label="Traditional Chinese" name="description_zh_cht">
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={isViewModalOpen}
        footer={null}
        onCancel={() => setIsViewModalOpen(false)}
      >
        <div className="view-container">
          <Row>
            <Col span={8}>Title ENG</Col>
            <Col span={16}>{initialValues.title_eng}</Col>
          </Row>
          <Row>
            <Col span={8}>Title ZH CH</Col>
            <Col span={16}>{initialValues.title_zh_ch}</Col>
          </Row>
          <Row>
            <Col span={8}>Title ZH CHT</Col>
            <Col span={16}>{initialValues.title_zh_cht}</Col>
          </Row>
          <Row>
            <Col span={8}>Cost</Col>
            <Col span={16}>{initialValues.cost}</Col>
          </Row>
          <Row>
            <Col span={8}>Token Amount</Col>
            <Col span={16}>{initialValues.token_amount}</Col>
          </Row>
          <Row>
            <Col span={8}>Status</Col>
            <Col span={16}>
              {initialValues.status === true ? "Active" : "Inactive"}
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default MembershipTable;
