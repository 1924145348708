import { Table, Modal, Input, Select, Form, Button, Spin } from "antd";
import { useState, useEffect } from "react";
import manageTables from "../../../services/ManageTables";
import { FormOutlined } from "@ant-design/icons";
import { getRecruiters, updateRecruiter } from "../../../services/Services";
import { openNotification } from "../../../services/NotificationService";
import "./styles.css";

const { Option } = Select;

const RecruiterTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [updateItem, setUpdateItem] = useState({
    id: null,
    status: null,
  });
  const [form2] = Form.useForm();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getRecruiters();
      let formattedData = manageTables(response.data?.rows, "recruiters");
      setOriginalData(formattedData.data);
      setData(formattedData.data);
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    const searchData = originalData.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
    searchData ? setData(searchData) : setData(originalData);
  };

  let handleEdit = (itemId) => {
    const selectItemStatus = data.filter((item) => item.key === itemId);
    form2.setFieldsValue({
      status:
        selectItemStatus[0].status.props.children === "Active" ? true : false,
    });
    setUpdateItem({
      id: itemId,
      status:
        selectItemStatus[0].status.props.children === "Active" ? true : false,
    });
    setModalVisible(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text) => <a href={`mailto:${text}`}>{text}</a>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div>
          <FormOutlined onClick={() => handleEdit(record.key)} />
        </div>
      ),
    },
  ];

  function handleChange(value) {
    setUpdateItem({ ...updateItem, status: value });
  }

  const handleSubmit = async (event) => {
    try {
      const response = await updateRecruiter(updateItem);
      openNotification(
        "Your Operations has been successfully completed",
        response?.status
      );
      fetchData();
      setModalVisible(false);
    } catch (err) {
      openNotification(
        err?.response?.data?.error?.message,
        err?.response?.status
      );
    }
  };

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Input
          placeholder="Search By Name"
          onChange={handleSearch}
          style={{ width: 300 }}
        />
      </div>

      <>
        {loading ? (
          <Spin size="large" />
        ) : data.length > 0 ? (
          <Table dataSource={data} columns={columns} pagination={true} />
        ) : (
          <h1>No results found</h1>
        )}
      </>

      <Modal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Form
          onFinish={handleSubmit}
          className="edit-status-form"
          form={form2}
          name="update"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Form.Item
            name="status"
            label="Status"
            initialValue={updateItem.status}
          >
            <Select onChange={handleChange}>
              <Option value={true}>Active</Option>
              <Option value={false}>Inactive</Option>
            </Select>
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ alignSelf: "flex-end" }}
          >
            Update
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default RecruiterTable;
