import { getCookie } from "../utils/utils";
import { http } from "./HttpClient";
import {Buffer} from 'buffer';

//auth service
export function userLogin(payload) {
  return http.post(`/user/login`, payload);
}

//payment
export function getPayment() {
  return http.get(`/admin/payment?size=10000`);
}

export function rejectPayment(id, payload) {
  return http.patch(`/admin/payment?id=${id}`, {
    status: "rejected",
    rejected_reason: payload,
  });
}

export function acceptPayment(id, payload) {
  return http.patch(`/admin/payment?id=${id}`, {
    status: payload,
    rejected_reason: "n/a",
  });
}

//SubcriptionInfo services
export function getSubcriptionInfo() {
  return http.get(`/master-data/subscription-info`);
}

export function createSubscriptionInfo(payload) {
  return http.post(`/master-data/subscription-info`, {
    feature: payload.feature,
    token_needed: payload.token_needed,
    additional_info_eng: payload.additional_info_eng,
    additional_info_zh_ch: payload.additional_info_zh_ch,
    additional_info_zh_cht: payload.additional_info_zh_cht,
    type: payload.type,
  });
}

export function updateSubscriptionInfo(payload) {
  return http.patch(`/master-data/subscription-info?id=${payload.key}`, {
    feature: payload.feature,
    token_needed: payload.token_needed,
    additional_info_eng: payload.additional_info_eng,
    additional_info_zh_ch: payload.additional_info_zh_ch,
    additional_info_zh_cht: payload.additional_info_zh_cht,
    type: payload.type,
  });
}

export function deleteSubscriptionInfo(payload) {
  return http.delete(`/master-data/subscription-info?id=${payload}`);
}

//MembershipPlans services
export function getMembershipPlans() {
  return http.get(`/master-data/membership-plans`);
}

export function createMembershipPlans(payload) {
  return http.post(`/master-data/membership-plans`, {
    title_eng: payload.title_eng,
    title_zh_ch: payload.title_zh_ch,
    title_zh_cht: payload.title_zh_cht,
    cost: payload.cost,
    token_amount: payload.token_amount,
    status: payload.status,
    description_eng: payload.description_eng,
    description_zh_ch: payload.description_zh_ch,
    description_zh_cht: payload.description_zh_cht,
  });
}

export function updateMembershipPlans(payload) {
  return http.patch(`/master-data/membership-plans?id=${payload.key}`, {
    title_eng: payload.title_eng,
    title_zh_ch: payload.title_zh_ch,
    title_zh_cht: payload.title_zh_cht,
    cost: payload.cost,
    token_amount: payload.token_amount,
    status: payload.status,
    description_eng: payload.description_eng,
    description_zh_ch: payload.description_zh_ch,
    description_zh_cht: payload.description_zh_cht,
  });
}

export function deleteMembershipPlans(payload) {
  return http.delete(`/master-data/membership-plans?id=${payload}`);
}

//Recruiters services
export function getRecruiters() {
  return http.get(`/admin/users/recruiter`);
}

export function updateRecruiter(payload) {
  return http.patch(`/admin/users/recruiter?id=${payload.id}`, {
    active: payload.status,
  });
}

//Freelance services
export function getFreelancers() {
  return http.get(`/admin/users/freelancer`);
}

export function updatefreelancer(payload) {
  return http.patch(`/admin/users/freelancer?id=${payload.id}`, {
    active: payload.status,
  });
}

// Admin services
export function getAdminUsers() {
  return http.get(`/admin/users`);
}

export function createAdmin(payload) {
  return http.post(`/admin/users/create`, {
    email: payload.email,
    firstName: payload.firstName,
    lastName: payload.lastName,
    password: payload.password,
  });
}

export function updateAdmin(payload) {
  return http.patch(`/admin/users/admin?id=${payload.id}`, {
    active: payload.status,
  });
}

export function deleteAdmin(payload) {
  return http.delete(`/admin/users/admin?id=${payload}`);
}

export function getServiceInvoices() {
  return http.get(`/admin/invoice?size=10000`);
}

export function deleteServiceInvoice(payload) {
  return http.delete(`/admin/invoice?id=${payload}`);
}

export function getDynamicDropdown(payload) {
  return http.get(`/master-data/eng/dropdowns/${payload}?from=0&size=1000`);
}

export function getBranding() {
  return http.get(`/admin/branding?size=10000`);
}

export function getCacheAdmin() {
  return http.get(`/master-data/cache/admin`);
}

export function getRecruiterEmail() {
  return http.get(`/admin/recruiter/info`);
}

export function createInvoice(payload, id) {
  return http.post(`/admin/invoice?recruiter_id=${id}`, {
    due_date: payload.dueDate,
    amount: payload.amount,
    purpose: payload.purpose,
  });
}

export function updateBranding(id, payload) {
  return http.patch(`/admin/branding?id=${id}`, {
    status: payload.status,
    deactivated_reason: payload.deactivatedReason,
  });
}

export function addDropdown(type, payload, tags) {
  return http.post(`/master-data/${payload.lang}/dropdowns/${type}`, {
    item: {
      title: payload.title,
      sub_headings: tags,
    },
  });
}

export function updateDropdown(type, payload, tags) {
  return http.patch(
    `/master-data/${payload.lang}/dropdowns/${type}?id=${payload.key}`,
    {
      item: {
        title: payload.title,
        sub_headings: tags,
      },
    }
  );
}

export function deleteDropdown(payload) {
  return http.delete(`/master-data/dropdowns/${payload}`);
}

export function updateTokens(payload) {
  return http.patch(`/master-data/cache/admin/token-exp-days`, {
    days: payload,
  });
}

export async function getImage(resource, type) {
  let name = '';
  let ext = '';

  if (resource) {
    const parts = resource.split(".");
    name = parts[0];
    ext = parts[1];
  }
  
  const mimeType = ext === 'png' ? 'image/png' : ext === 'jpg' ? 'image/jpeg' : 'image/png'; // Adjust MIME type based on the actual image type
  const token = getCookie('_auth');
  return fetch(`${process.env.REACT_APP_API_URL}/user/asset/${type}/${name}?mimeType=.${ext}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
    }
  })
    .then(response => {
      if (response.ok) {
        return response.arrayBuffer();
      } else {
        throw new Error("Error fetching image");
      }
    })
    .then(buffer => {
      const base64ImageString = Buffer.from(buffer).toString("base64");
      const srcValue = `data:${mimeType};base64,${base64ImageString}`;
      return srcValue;
    })
    .catch(error => {
      console.log("Error fetching image:", error);
      throw error;
    });
}
