import React from "react";
import FreelancerTable from "../../components/common/freelancerTable";
import "./users.css";

export default function Freelancers() {

  return (
    <>
      <div className="user-top-container">
        <h3>Manage Freelancers</h3>
      </div>
      <FreelancerTable />
    </>
  )
}
