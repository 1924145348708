import {
  Table,
  Modal,
  Input,
  Select,
  Popconfirm,
  Form,
  DatePicker,
  Button,
  Row,
  Col,
  Tag,
  Spin
} from "antd";
import { useState, useEffect } from "react";
import manageTables, { getFormattedDate } from "../../../services/ManageTables";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import {
  getServiceInvoices,
  deleteServiceInvoice,
  getRecruiterEmail,
  createInvoice,
} from "../../../services/Services";
import { openNotification } from "../../../services/NotificationService";
import moment from 'moment';
import "./styles.css";

const { Option } = Select;

const ServiceInvoiceTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataRecruiter, setDataRecruiter] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRecruiter, setSelectedRecruiter] = useState(null);
  const [viewModel, setViewModel] = useState(false);
  const [oldData, setOldData] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState([]);

  const [form] = Form.useForm();
  const generatedList = [
    { value: "", label: "All" },
    { value: "admin", label: "Admin" },
    { value: "system", label: "System" },
  ];
  const status = [
    { value: "", label: "All" },
    { value: "paid", label: "Paid" },
    { value: "pending", label: "Pending" },
    { value: "pass due", label: "Pass Due" },
  ];

  useEffect(() => {
    fetchData();
    fetchDataRecruiter();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getServiceInvoices();
      let formattedData = manageTables(response.data?.data, "serviceInvoice");
      setOriginalData(formattedData.data);
      setData(formattedData.data);
      setOldData(response.data.data);
    } catch (err) {
      console.error("Error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataRecruiter = async () => {
    try {
      const response = await getRecruiterEmail();
      setDataRecruiter(response.data.rows);
    } catch (err) {
      console.error("Error: ", err);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    const searchData = originalData.filter((item) =>
      item.invoice_id.toLowerCase().includes(query.toLowerCase())
    );
    searchData ? setData(searchData) : setData(originalData);
  };

  const handleDelete = async (itemId) => {
    try {
      const response = await deleteServiceInvoice(itemId);
      openNotification("Successfully deleted", response?.status);
      setTimeout(function () {
        fetchData();
      }, 1000);
    } catch (err) {
      openNotification(
        err?.response?.data?.error?.message,
        err?.response?.status
      );
    }
  };

  const onFinish = async (values) => {
    try {
      const response = await createInvoice(
        values,
        selectedRecruiter?.profileDataId
      );
      openNotification(
        "Your Operations has been successfully completed",
        response?.status
      );
      fetchData();
      setModalVisible(false);
      form.resetFields();
      setSelectedRecruiter("");
    } catch (err) {
      openNotification(
        err?.response?.data?.error?.message,
        err?.response?.status
      );
    }
  };

  const handleView = (value) => {
    setViewModel(true);
    const invoice = oldData.find((invoice) => invoice._id === value);
    setSelectedInvoice(invoice);
  };

  const closeModal = () => {
    setViewModel(false);
    setSelectedInvoice("");
  };

  const handleRecruiterChange = (value) => {
    const recruiter = dataRecruiter.find(
      (recruiter) => recruiter.email === value
    );
    setSelectedRecruiter(recruiter);
  };

  const columns = [
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      key: "invoice_date",
      sorter: (a, b) => a.invoice_date.localeCompare(b.invoice_date),
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      sorter: (a, b) => a.due_date.localeCompare(b.due_date),
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Recruiter Email",
      dataIndex: "recruiter_email",
      key: "recruiter_email",
      render: (text) => <a href={`mailto:${text}`}>{text}</a>,
    },
    {
      title: "Generated By",
      dataIndex: "generated_by",
      key: "generated_by",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        let status = record.status;
        let tagColor = "";

        switch (status) {
          case "paid":
            tagColor = "#4CAF50";
            break;
          case "past_due":
            tagColor = "#F44336";
            break;
          case "pending":
            tagColor = "#FFC107";
            break;
          default:
            break;
        }
        return <Tag color={tagColor}>{status}</Tag>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div>
          {record.generated_by === "admin" ? (
            <Popconfirm
              title="Are you sure you want to delete?"
              onConfirm={() => handleDelete(record.key)}
            >
              <DeleteOutlined />
            </Popconfirm>
          ) : (
            ""
          )}
          <EyeOutlined onClick={() => handleView(record.key)} />
        </div>
      ),
    },
  ];

  function handleChange(value, name) {
    let searchData = data;
    if (name === "generated") {
      searchData = originalData.filter((item) =>
        item.generated_by.toLowerCase().includes(value.toLowerCase())
      );
    } else {
      searchData = originalData.filter((item) =>
        item.status.toLowerCase().includes(value.toLowerCase())
      );
    }
    setData(searchData);
  }

  useEffect(() => {}, [data]);

  return (
    <div>
      <div className="filter-container" style={{ marginBottom: 16 }}>
        <div className="filter-left">
          <Input
            placeholder="Search By Invoice Id"
            onChange={handleSearch}
            style={{ width: 300 }}
          />
        </div>
        <div className="filter-right">
          <div className="filter-wrapper">
            <label>Generated by</label>
            <Select
              className="filter-dropdown"
              defaultValue={generatedList[0]}
              options={generatedList}
              onChange={(selectedOption) =>
                handleChange(selectedOption, "generated")
              }
            ></Select>
          </div>
          <div className="filter-wrapper">
            <label>Status</label>
            <Select
              className="filter-dropdown"
              defaultValue={status[0]}
              options={status}
              onChange={(selectedOption) =>
                handleChange(selectedOption, "status")
              }
            ></Select>
          </div>
        </div>
        <Button type="primary" onClick={() => setModalVisible(true)}>
          Genarate Invoice
        </Button>
      </div>

      <>
        {loading ? (
          <Spin size="large" />
        ) : data.length > 0 ? (
          <Table dataSource={data} columns={columns} pagination={true} />
        ) : (
          <h1>No results found</h1>
        )}
      </>

      <Modal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <div className="invoice-form-container">
          <Form onFinish={onFinish} form={form}>
            <Form.Item
              name="recruiterEmail"
              label="Recruiter Email"
              rules={[{ required: true, message: "Please enter an email" }]}
            >
              <Select onChange={handleRecruiterChange}>
                {dataRecruiter.map((recruiter) => (
                  <Option key={recruiter.email} value={recruiter.email}>
                    {recruiter.email}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Recruiter Name">
              <label className="name-col">
                {selectedRecruiter?.firstName} {selectedRecruiter?.lastName}
              </label>
            </Form.Item>
            <Form.Item
              name="amount"
              label="Amount"
              rules={[{ required: true, message: "Please enter an amount" }]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              name="dueDate"
              label="Due Date"
              rules={[{ required: true, message: "Please select a due date" }]}
            >
              <DatePicker
                inputReadOnly
                disabledDate={(current) =>
                  current && current < moment().startOf("day")
                }
              />
            </Form.Item>

            <Form.Item
              name="purpose"
              label="Purpose"
              rules={[{ required: true, message: "Please enter a purpose" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item className="submit-btn-wrapper">
              <Button type="primary" htmlType="submit">
                Send Invoice
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <Modal open={viewModel} onCancel={() => closeModal()} footer={null}>
        <div className="brand-view-container">
          <Row>
            <Col span={8}>Invoice Id</Col>
            <Col span={16}>{selectedInvoice._id}</Col>
          </Row>
          <Row>
            <Col span={8}>Amount</Col>
            <Col span={16}>{selectedInvoice?._source?.amount}</Col>
          </Row>
          <Row>
            <Col span={8}>Commission rate</Col>
            <Col span={16}>{selectedInvoice?._source?.commission_rate}</Col>
          </Row>
          <Row>
            <Col span={8}>Company name</Col>
            <Col span={16}>{selectedInvoice._source?.company_name}</Col>
          </Row>
          <Row>
            <Col span={8}>Created Date</Col>
            <Col span={16}>
              {getFormattedDate(selectedInvoice._source?.createdAt)}
            </Col>
          </Row>
          <Row>
            <Col span={8}>Currency</Col>
            <Col span={16}>{selectedInvoice._source?.currency}</Col>
          </Row>
          <Row>
            <Col span={8}>Due Date</Col>
            <Col span={16}>
              {getFormattedDate(selectedInvoice._source?.due_date)}
            </Col>
          </Row>
          <Row>
            <Col span={8}>Generated By</Col>
            <Col span={16}>{selectedInvoice._source?.generated_by}</Col>
          </Row>
          <Row>
            <Col span={8}>Purpose</Col>
            <Col span={16}>{selectedInvoice._source?.purpose}</Col>
          </Row>
          <Row>
            <Col span={8}>Recruiter email</Col>
            <Col span={16}>{selectedInvoice._source?.recruiter_email}</Col>
          </Row>
          <Row>
            <Col span={8}>Recruiter ID</Col>
            <Col span={16}>{selectedInvoice._source?.recruiter_id}</Col>
          </Row>
          <Row>
            <Col span={8}>Recruiter name</Col>
            <Col span={16}>{selectedInvoice._source?.recruiter_name}</Col>
          </Row>
          <Row>
            <Col span={8}>Status</Col>
            <Col span={16}>{selectedInvoice._source?.status}</Col>
          </Row>
          <Row>
            <Col span={8}>Updated Date</Col>
            <Col span={16}>
              {getFormattedDate(selectedInvoice._source?.updatedAt)}
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default ServiceInvoiceTable;
