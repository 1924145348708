import {
  Table,
  Form,
  Button,
  Modal,
  Input,
  Select,
  Popconfirm,
  Row,
  Col,
  Tag,
  Spin,
  Image
} from "antd";
import { useState, useEffect } from "react";
import manageTables, { getFormattedDate } from "../../../services/ManageTables";
import { CheckOutlined, StopOutlined, EyeOutlined } from "@ant-design/icons";
import {
  getPayment,
  rejectPayment,
  acceptPayment,
  getImage
} from "../../../services/Services";
import { openNotification } from "../../../services/NotificationService";
import "./styles.css";
import constants from "../../../utils/constants";

const InvoiceTable = () => {
  const purpose = [
    { value: "", label: "Select purpose" },
    { value: "membership", label: "Membership" },
    { value: "commission", label: "Commission" },
  ];
  const status = [
    { value: "", label: "Select status" },
    { value: "pending", label: "Pending" },
    { value: "confirmed", label: "Confirmed" },
    { value: "rejected", label: "Rejected" },
  ];
  const [tableData, setTableData] = useState([]);
  // eslint-disable-next-line
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTableData, setFilteredTableData] = useState(tableData);
  const [selectedPurpose, setSelectedPurpose] = useState(purpose[0]);
  const [selectedStatus, setSelectedStatus] = useState(status[0]);
  const [viewModel, setViewModel] = useState(false);
  const [rejectModel, setRejectModel] = useState(false);
  const [oldData, setOldData] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState([]);
  const [form] = Form.useForm();
  const [rejectedReason, setRejectedReason] = useState("");
  const [loading, setLoading] = useState(true);
  const [viewLoading, setViewLoading] = useState(true);
  const [imgPath, setImgPath] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getPayment({ cacheBuster: Date.now() });
      let formattedData = manageTables(response.data?.data, "invoiceTable");
      setTableData(formattedData.data);
      setOldData(response.data.data);
      setFilteredTableData(formattedData.data);
    } catch (err) {
      console.error("Error: ", err);
    } finally {
      setLoading(false);
    }
  };
  

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    let filteredData;
    if (query) {
      filteredData = tableData.filter((item) =>
        item.company_name.toLowerCase().includes(query.toLowerCase())
      );
    } else {
      filteredData = tableData;
    }
    setFilteredTableData(filteredData);
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      sorter: (a, b) => a.company_name.localeCompare(b.company_name),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
    {
      title: "Recruiter Email",
      dataIndex: "recruiter_email",
      key: "recruiter_email",
      sorter: (a, b) => a.recruiter_email.localeCompare(b.recruiter_email),
      render: (text) => <a href={`mailto:${text}`}>{text}</a>,
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      key: "purpose",
      sorter: (a, b) => a.purpose.localeCompare(b.purpose),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, record) => <Tag color="green">{`${record.amount} ${record.amount ? record.currency : 'N/A'}`}</Tag>
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        let status = record.status;
        let tagColor = "";

        switch (status) {
        case "confirmed":
        tagColor = "#4CAF50";
        break;
        case "rejected":
        tagColor = "#F44336";
        break;
        case "pending":
        tagColor = "#FFC107";
        break;
        default:
        break;
        }
        return <Tag color={tagColor}>{status}</Tag>
      }
    },
    {
      title: "Action",
      key: "action",
      width: 130,
      render: (_, record) => (
        <div>
          {record.status === "pending" ? (
            <Popconfirm
              title="Are you Sure you want to confirm?"
              onConfirm={() => handleAccept(record.key)}
            >
              <CheckOutlined />
            </Popconfirm>
          ) : (
            ""
          )}
          {record.status === "pending" ? (
            <Popconfirm
              title="Are you Sure you want to reject?"
              onConfirm={() => handleReject(record.key)}
            >
              <StopOutlined />
            </Popconfirm>
          ) : (
            ""
          )}
          <EyeOutlined onClick={() => handleView(record.key)} />
        </div>
      ),
    },
  ];

  const handleChange = (value, name) => {
    if (value && value !== null && value !== "") {
      setSearchQuery(value);
      let filteredData = tableData.filter((item) => {
        if (name === "purpose") {
          setSelectedPurpose(value);
          setSelectedStatus(status[0]);
          return item.purpose.toLowerCase().includes(value.toLowerCase());
        } else {
          setSelectedStatus(value);
          setSelectedPurpose(purpose[0]);
          return item.status.toLowerCase().includes(value.toLowerCase());
        }
      });
      setFilteredTableData(filteredData);
    } else {
      setFilteredTableData(tableData);
      setSelectedPurpose(purpose[0]);
      setSelectedStatus(status[0]);
    }
  };

  const handleAccept = async (itemId) => {
    try {
      const response = await acceptPayment(itemId, "confirmed");
      openNotification(
        "Your Operations has been successfully completed",
        response?.status
      );
      setTimeout(function () {
        fetchData();
      }, 1000);
    } catch (err) {
      openNotification(
        err?.response?.data?.error?.message,
        err?.response?.status
      );
    }
  };

  const handleView = async (itemId) => {
    setViewLoading(true)
    const invoice = oldData.find((invoice) => invoice._id === itemId);
    setSelectedInvoice(invoice);
    setViewModel(true);
    try {
      const imagePath = await getImage(invoice._source.proof, constants.IMAGE_FETCH_TYPES.PAYMENT);
      if (imagePath && imagePath !== null) {
        setImgPath(imagePath);
      }
    } catch (err) {
      console.log(err)
    } finally {
      setViewLoading(false)
    }
  };

  const handleReject = (itemId) => {
    setRejectModel(true);
    const invoice = oldData.find((invoice) => invoice._id === itemId);
    setSelectedInvoice(invoice);
  };

  const closeModal = () => {
    setViewModel(false);
    setSelectedInvoice("");
    setRejectModel(false);
  };

  const handleReasonChange = (e) => {
    setRejectedReason(e.target.value);
  };

  const handleSubmit = async (values) => {
    try {
      const response = await rejectPayment(
        selectedInvoice._id,
        values.rejectedReason
      );
      openNotification(
        "Your Operations has been successfully completed",
        response?.status
      );
      setTimeout(function () {
        fetchData();
        setRejectModel(false);
      }, 1000);
    } catch (err) {
      openNotification(
        err?.response?.data?.error?.message,
        err?.response?.status
      );
    }
  };


  return (
    <div>
      <div className="filter-container" style={{ marginBottom: 16 }}>
        <div className="filter-left">
          <Input
            placeholder="Search By Title"
            onChange={handleSearch}
            style={{ width: 300 }}
          />
        </div>
        <div className="filter-right">
          <Select
            className="filter-dropdown"
            defaultValue={selectedPurpose}
            options={purpose}
            onChange={(selectedOption) =>
              handleChange(selectedOption, "purpose")
            }
          ></Select>
          <Select
            className="filter-dropdown"
            defaultValue={selectedStatus}
            options={status}
            onChange={(selectedOption) =>
              handleChange(selectedOption, "status")
            }
          ></Select>
        </div>
      </div>
      <>
        {loading ? (
          <Spin size="large" />
        ) : filteredTableData.length > 0 ? (
          <Table
            dataSource={filteredTableData}
            columns={columns}
            pagination={true}
          />
        ) : (
          <h1>No results found</h1>
        )}
      </>

      <Modal open={viewModel} onCancel={() => closeModal()} footer={null}>
        {viewLoading ? (
          <Spin size="large" />
        ) : (selectedInvoice?._source && (
          <div className="brand-view-container">
            <Row>
              <Col span={8}>Transaction ID</Col>
              <Col span={16}>{selectedInvoice._id}</Col>
            </Row>
            <Row>
              <Col span={8}>Amount</Col>
              <Col span={16}>{selectedInvoice._source.amount}</Col>
            </Row>
            <Row>
              <Col span={8}>Created Date</Col>
              <Col span={16}>
                {getFormattedDate(selectedInvoice._source.createdAt, true)}
              </Col>
            </Row>
            <Row>
              <Col span={8}>Currency</Col>
              <Col span={16}>{selectedInvoice._source.currency}</Col>
            </Row>
            <Row>
              <Col span={8}>Due date</Col>
              <Col span={16}>
                {getFormattedDate(selectedInvoice?._source.invoice?.due_date)}
              </Col>
            </Row>
            <Row>
              <Col span={8}>Purpose</Col>
              <Col span={16}>{selectedInvoice._source.purpose}</Col>
            </Row>
            <Row>
              <Col span={8}>Recruiter email</Col>
              <Col span={16}>{selectedInvoice._source.recruiter_email}</Col>
            </Row>
            <Row>
              <Col span={8}>Recruiter ID</Col>
              <Col span={16}>{selectedInvoice._source.recruiter_id}</Col>
            </Row>
            <Row>
              <Col span={8}>Status</Col>
              <Col span={16}>{selectedInvoice._source.status}</Col>
            </Row>
            <Row>
              <Col span={8}>Updated date</Col>
              <Col span={16}>
                {getFormattedDate(selectedInvoice._source.updatedAt, true)}
              </Col>
            </Row>
            <Row>
              <Col span={8}>Invoice ID</Col>
              <Col span={16}>{selectedInvoice._source.invoice_id}</Col>
            </Row>
            <Row>
              <Col span={8}>Payment method</Col>
              <Col span={16}>{selectedInvoice._source.payment_method}</Col>
            </Row>
            <Row>
              <Col span={8}>Proof</Col>
              <Col span={16}>
                <Image src={imgPath} alt="Proof" />
              </Col>
            </Row>
            <Row>
              <Col span={8}>Recruiter company name</Col>
              <Col span={16}>
                {selectedInvoice._source.recruiter_company_name}
              </Col>
            </Row>
            {selectedInvoice.status !== "confirmed" && (
              <Row>
                <Col span={8}>Rejected reason</Col>
                <Col span={16}>{selectedInvoice._source.rejected_reason}</Col>
              </Row>
            )}
          </div>
        ))}
      </Modal>
      <Modal open={rejectModel} onCancel={() => closeModal()} footer={null}>
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item
            label="Rejected reason"
            name="rejectedReason"
            rules={[
              { required: true, message: "Please enter a rejected reason" },
            ]}
          >
            <Input.TextArea
              value={rejectedReason}
              onChange={handleReasonChange}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default InvoiceTable;
