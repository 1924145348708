import React from "react";
import BrandingTable from "../../components/common/brandingTable";

export default function Branding() {
  return (
    <>
      <h3>Branding</h3>
      <BrandingTable />
    </>
  );
}
