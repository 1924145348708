export function getCachedTypes(content) {
  const outputArray = [];

  for (let i = 0; i < content.length; i++) {
    const name = content[i];
    if (name !== "job_function") {
      const value = name
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      outputArray.push({ name, value });
    }
  }
  const typeDropdown = outputArray.map((item) => ({
    value: item.name,
    label: item.value,
  }));
  return typeDropdown;
}
