import { useState } from "react";
import AdminTable from "../../components/common/adminTable";
import "./users.css";

export default function Admin() {
  const [type] = useState(localStorage.getItem('type'));

  return (
    type === 'master' ?
    <>
      <div className="user-top-container">
        <h3>Manage Admin Users</h3>
      </div>
      <AdminTable />
    </>
     : <h2>You don't have permission to access this page</h2>

  );
}