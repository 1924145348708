import {
  Table,
  Modal,
  Input,
  Select,
  Row,
  Col,
  Image,
  Form,
  Button,
  Tooltip,
  Tag,
  Spin,
} from "antd";
import { useState, useEffect } from "react";
import manageTables, { getFormattedDate } from "../../../services/ManageTables";
import {
  FormOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  getBranding,
  getImage,
  updateBranding,
} from "../../../services/Services";
import { openNotification } from "../../../services/NotificationService";
import "./styles.css";
import constants from "../../../utils/constants";

const { Option } = Select;

const BrandingTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [imgPath, setImgPath] = useState("");

  const [form] = Form.useForm();
  const [showTextArea, setShowTextArea] = useState(false);

  const status = [
    { value: "", label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "Activated", label: "Activated" },
    { value: "Deactivated", label: "Deactivated" },
    { value: "Expired", label: "Expired" },
    { value: "Deleted", label: "Deleted" },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectedItem(selectedItem);
  }, [selectedItem]);

  const fetchData = async () => {
    try {
      const response = await getBranding();
      let formattedData = manageTables(response.data?.data, "branding");
      setApiData(response?.data?.data);
      setOriginalData(formattedData.data);
      setData(formattedData.data);
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = (value) => {
    setShowTextArea(value === "deactivated");
  };

  const onFinish = async (values) => {
    try {
      const response = await updateBranding(selectedItem, values);
      openNotification(
        "Your Operations has been successfully completed",
        response?.status
      );
      setTimeout(function () {
        fetchData();
        setModalVisible(false);
        form.resetFields();
      }, 1000);
    } catch (err) {
      openNotification(
        err?.response?.data?.error?.message,
        err?.response?.status
      );
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    const searchData = originalData.filter((item) =>
      item.title.toLowerCase().includes(query.toLowerCase())
    );
    searchData ? setData(searchData) : setData(originalData);
  };

  let handleEdit = (itemId) => {
    setSelectedItem(itemId);
    setShowTextArea(false);
    setModalVisible(true);
    form.resetFields();
  };
  let handleOpen = (itemId) => {
    handleSelectedRow(itemId, "view");
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "Publish Date",
      dataIndex: "publish_date",
      key: "publish_date",
    },
    {
      title: "Expiry Date",
      dataIndex: "exp_date",
      key: "exp_date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        const status = record.status;
        let tagColor = "";

        switch (status) {
          case "deactivated":
            tagColor = "#888888";
            break;
          case "expired":
            tagColor = "#FF5252";
            break;
          case "pending":
            tagColor = "#FFD700";
            break;
          case "activated":
            tagColor = "#00C853";
            break;
          case "deleted":
            tagColor = "#E0E0E0";
            break;
          default:
            // Handle cases where status doesn't match any known values
            // You can set a default color or handle the situation accordingly
            break;
        }

        return (
          <span>
            {<Tag color={tagColor}>{status}</Tag>}{" "}
            {record.reason ? (
              <Tooltip title={record.reason}>
                <QuestionCircleOutlined />
              </Tooltip>
            ) : (
              ""
            )}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) =>
        record.status !== "expired" &&
        record.status !== "deleted" &&
        record.status !== "deactivated" ? (
          <div>
            <FormOutlined onClick={() => handleEdit(record.key)} />
            <EyeOutlined onClick={() => handleOpen(record.key)} />
          </div>
        ) : (
          <EyeOutlined onClick={() => handleOpen(record.key)} />
        ),
    },
  ];

  function handleChange(value, name) {
    let searchData = originalData.filter(
      (item) => item.status.toLowerCase() === value.toLowerCase()
    );
    setData(searchData);
  }

  let handleSelectedRow = async (itemId, type) => {
    const clickedItem = apiData.filter((item) => item._id === itemId);
    setSelectedItem(clickedItem[0]._source);
    if (type === "view") {
      setIsViewModalOpen(true);
      setLoading(true);
      try {
        const imagePath = await getImage(
          clickedItem[0]?._source?.image,
          constants.IMAGE_FETCH_TYPES.BRANDING
        );
        if (imagePath && imagePath !== null) {
          setImgPath(imagePath);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <div className="filter-container" style={{ marginBottom: 16 }}>
          <div className="filter-left">
            <Input
              placeholder="Search By Title"
              onChange={handleSearch}
              style={{ width: 300 }}
            />
          </div>
          <div className="filter-right">
            <div className="filter-wrapper">
              <label>Filter Status</label>
              <Select
                className="filter-dropdown"
                defaultValue={status[0]}
                options={status}
                onChange={(selectedOption) => handleChange(selectedOption)}
              ></Select>
            </div>
          </div>
        </div>
      </div>

      <>
        {loading ? (
          <Spin size="large" />
        ) : data.length > 0 ? (
          <Table dataSource={data} columns={columns} pagination={true} />
        ) : (
          <h1>No results found</h1>
        )}
      </>

      <Modal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        {loading ? (
          <Spin size="large" />
        ) : (
          <Form form={form} onFinish={onFinish}>
            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true }]}
            >
              <Select onChange={handleSelectChange}>
                <Option value="activated">Activated</Option>
                <Option value="deactivated">Deactivated</Option>
              </Select>
            </Form.Item>

            {showTextArea && (
              <Form.Item
                name="deactivatedReason"
                label="Deactivated Reason"
                rules={[{ required: true }]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            )}

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>

      <Modal
        open={isViewModalOpen}
        footer={null}
        onCancel={() => setIsViewModalOpen(false)}
      >
        <div className="brand-view-container">
          <Row>
            <Col span={8}>Company Name</Col>
            <Col span={16}>{selectedItem?.company_name}</Col>
          </Row>
          <Row>
            <Col span={8}>Recruiter Name</Col>
            <Col span={16}>{selectedItem?.recruiter_name}</Col>
          </Row>
          <Row>
            <Col span={8}>Title</Col>
            <Col span={16}>{selectedItem?.title}</Col>
          </Row>
          <Row>
            <Col span={8}>Type</Col>
            <Col span={16}>{selectedItem?.type}</Col>
          </Row>
          <Row>
            <Col span={8}>Publish Date</Col>
            <Col span={16}>{getFormattedDate(selectedItem?.publish_date)}</Col>
          </Row>
          {selectedItem?.type !== "corporate_logo_branding" && (
            <>
              <Row className="desc-row">
                <Col span={8}>Description</Col>
                <Col span={16}>{selectedItem?.description}</Col>
              </Row>
              <Row>
                <Col span={8}>URL</Col>
                <Col span={16}>{selectedItem?.url}</Col>
              </Row>
            </>
          )}
          ;
          {loading ? (
            <Spin size="small" />
          ) : (
            <Row className="image-row">
              <Col span={8}>Image</Col>
              <Col span={16}>
                <Image src={imgPath} width={200} />
              </Col>
            </Row>
          )}
          <Row>
            <Col span={8}>Tokens required</Col>
            <Col span={16}>{selectedItem?.token_required}</Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default BrandingTable;
