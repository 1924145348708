import axios from 'axios';
import {getCookie} from '../utils/utils';


let HttpClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`
})
// Add a request interceptor
HttpClient.interceptors.request.use(function (config) {
  // Do something before request is sent
  const token = getCookie('_auth');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
HttpClient.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if(error.response.data.error.status === 401){
    //alert(error.response.data.error.message);
    deleteCookie('_auth');
    deleteCookie('_auth_state');
    deleteCookie('_auth_storage');
    window.location.href ='/';
  }
  return Promise.reject(error);
});

const deleteCookie = (cookieName) => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const http = HttpClient;