import {
  PoundCircleOutlined,
  SnippetsOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Popconfirm } from "antd";
import { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useSignOut } from "react-auth-kit";

import "./styles.css";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const masterItems = [
  getItem("Users", "sub1", <UserOutlined />, [
    getItem("Admin", "admin"),
    getItem("Recruiters", "recruiters"),
    getItem("Freelancers", "freelancers"),
  ]),
  getItem("Content", "sub2", <SnippetsOutlined />, [
    getItem("Dropdown", "dropdown"),
    getItem("Branding", "branding"),
  ]),
  getItem("Subscriptions", "sub3", <AppstoreAddOutlined />, [
    getItem("Membership Plans", "membership-plans"),
    getItem("Subscription Info", "subcription-info"),
  ]),
  getItem("Payments", "sub4", <PoundCircleOutlined />, [
    getItem("Payment Approval", "payment-approval"),
    getItem("Service Invoices", "service-invoices"),
  ]),
];

const adminItems = [
  getItem("Users", "sub1", <UserOutlined />, [
    getItem("Recruiters", "recruiters"),
    getItem("Freelancers", "freelancers"),
  ]),
  getItem("Content", "sub2", <SnippetsOutlined />, [
    getItem("Dropdown", "dropdown"),
    getItem("Branding", "branding"),
  ]),
  getItem("Subscriptions", "sub3", <AppstoreAddOutlined />, [
    getItem("Membership Plans", "membership-plans"),
    getItem("Subscription Info", "subcription-info"),
  ]),
  getItem("Payments", "sub4", <PoundCircleOutlined />, [
    getItem("Payment Approval", "payment-approval"),
    getItem("Service Invoices", "service-invoices"),
  ]),
];

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [type] = useState(localStorage.getItem("type"));
  const navigate = useNavigate();
  const signOut = useSignOut();

  const onClick = (e) => {
    navigate(e.key);
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Link className="logo-wrapper" to='/dashboard/recruiters'>
          <img src="/assets/logo.png" alt="Admin dashboard" />
          <span className="home-txt">
            ME<span style={{ color: "#39BEC1" }}>2</span>WORKS
          </span>
        </Link>
        <Menu
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={type === "admin" ? adminItems : masterItems}
          onClick={onClick}
        />
      </Sider>
      <Layout className="site-layout">
        <Header className="dashboard-header">
          <h3>Admin Dashboard</h3>

          <Popconfirm
            title="Are you sure you want to logout?"
            onConfirm={() => signOut()}
          >
            <PoweroffOutlined />
          </Popconfirm>
        </Header>
        <Content
          style={{
            margin: "0 16px",
          }}
        >
          <div>
            <Outlet />
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        ></Footer>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
