import React from 'react';
import ServiceInvoiceTable from '../../components/common/serviceInvoiceTable';

export default function ServiceInvoices() {
  return (
    <>
      <div className="user-top-container">
        <h3>Service Invoice</h3>
      </div>
      <ServiceInvoiceTable/>
    </>
  )
}
