import {
  Table,
  Modal,
  Input,
  Select,
  Popconfirm,
  Button,
  Form,
  InputNumber,
  Spin
} from "antd";
import { useState, useEffect } from "react";
import manageTables from "../../../services/ManageTables";
import { FormOutlined, DeleteOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  getSubcriptionInfo,
  updateSubscriptionInfo,
  deleteSubscriptionInfo,
  createSubscriptionInfo,
  getCacheAdmin,
  updateTokens,
} from "../../../services/Services";
import { openNotification } from "../../../services/NotificationService";
import "./styles.css";

const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 18, span: 14 },
};

const SubscriptionTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [tokenClass, setTokenClass] = useState("disabled");
  const [isDisabled, setIsDisabled] = useState(true);
  const [tokens, setTokens] = useState(0);
  const [form] = Form.useForm();

  const [initialValues, setInitialValues] = useState({
    key: null,
    feature: null,
    token_needed: null,
    additional_info_eng: null,
    additional_info_zh_ch: null,
    additional_info_zh_cht: null,
    type: null,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getSubcriptionInfo();
      const cachedContent = await getCacheAdmin();
      const noOfTokens = cachedContent?.data?.DEFAULT_TOKEN_EXP_IN_DAYS;
      setTokens(noOfTokens);
      let formattedData = manageTables(response.data?.data, "subscription");
      setApiData(response.data.data);
      setOriginalData(formattedData.data);
      setData(formattedData.data);
    } catch (err) {
      console.log("Error: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    const searchData = originalData.filter((item) =>
      item.feature.toLowerCase().includes(query.toLowerCase())
    );
    searchData ? setData(searchData) : setData(originalData);
  };

  let handleEdit = (itemId) => {
    const selectItemStatus = apiData.filter((item) => item.id === itemId);
    const newFormValues = {
      key: selectItemStatus[0].id,
      feature: selectItemStatus[0].feature,
      token_needed: selectItemStatus[0].token_needed,
      additional_info_eng: selectItemStatus[0].additional_info_eng,
      additional_info_zh_ch: selectItemStatus[0].additional_info_zh_ch,
      additional_info_zh_cht: selectItemStatus[0].additional_info_zh_cht,
      type: selectItemStatus[0].type,
    };
    form.setFieldsValue(newFormValues);
    setInitialValues(newFormValues);
    setModalVisible(true);
  };

  const columns = [
    {
      title: "Feature",
      dataIndex: "feature",
      key: "feature",
      sorter: (a, b) => a.feature.localeCompare(b.feature),
    },
    {
      title: "Token needed",
      dataIndex: "token_needed",
      key: "token_needed",
    },
    {
      title: "Additional Info",
      dataIndex: "additional_info_eng",
      key: "additional_info_eng",
    },
    {
      title: "Simplified Chinese",
      dataIndex: "additional_info_zh_ch",
      key: "additional_info_zh_ch",
    },
    {
      title: "Traditional Chinese",
      dataIndex: "additional_info_zh_cht",
      key: "additional_info_zh_cht",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div>
          <FormOutlined onClick={() => handleEdit(record.key)} />
          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={() => handleDelete(record.key)}
          >
            <DeleteOutlined />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleDelete = async (itemId) => {
    try {
      const response = await deleteSubscriptionInfo(itemId);
      openNotification('Successfully deleted', response?.status);
      setTimeout(function () {
        fetchData();
      }, 1000);
    } catch (err) {
      openNotification(err?.response?.data?.error?.message, err?.response?.status);
    }
  };

  const handleUpdate = async (values) => {
    try {
      const response = await updateSubscriptionInfo(values);
      openNotification('Your Operations has been successfully completed', response?.status);
      setTimeout(function () {
        fetchData();
      }, 1000);
      setModalVisible(false);
    } catch (err) {
      openNotification(err?.response?.data?.error?.message, err?.response?.status);
    }
  };

  const showModal = () => {
    form.setFieldsValue({
      key: null,
      feature: null,
      token_needed: null,
      additional_info_eng: "",
      additional_info_zh_ch: "",
      additional_info_zh_cht: "",
      type: null
    });
    setIsModalOpen(true);
  };

  const onFinish = async (values) => {
    try {
      const response = await createSubscriptionInfo(values);
      openNotification('Your Operations has been successfully completed', response?.status);
      fetchData();
      setIsModalOpen(false);
      form.resetFields();
    } catch (err) {
      openNotification(err?.response?.data?.error?.message, err?.response?.status);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const setEditable = (status) => {
    setIsDisabled(!status);
    setTokenClass(status ? "" : "disabled");
  };

  const confirmTokenChange = async () => {
    setIsDisabled(true);
    setTokenClass("disabled");
    try {
      const response = await updateTokens(tokens);
      openNotification('Your Operations has been successfully completed', response?.status);
    } catch (err) {
      openNotification(err?.response?.data?.error?.message, err?.response?.status);
    }
  };

  const handleTokenChange = (event) => {
    setTokens(event.target.value);
  };

  return (
    <div>
      <div className="top-action-container">
        <Input
          placeholder="Search By Feature"
          onChange={handleSearch}
          style={{ width: 300 }}
        />
        <div className="token-wrapper">
          <span className="token-label">Tokens Validity (in days)</span>
          <Input className={tokenClass} value={tokens} disabled={isDisabled} onChange={handleTokenChange} />
          {isDisabled ? (
            <FormOutlined className="edit-token" onClick={() => setEditable(true)} />
          ) : (
            <>
              <CheckOutlined className="edit-token confirm" onClick={confirmTokenChange} />
              <CloseOutlined className="edit-token close" onClick={() => setEditable(false)} />
            </>
          )
        }
        </div>
      </div>
      
        <Button type="primary" onClick={showModal}>
          Add
        </Button>
      
      <>
        {loading ? (
          <Spin size="large" />
        ) : data.length > 0 ? (
          <Table dataSource={data} columns={columns} pagination={true} />
        ) : (
          <h1>No results found</h1>
        )}
      </>

      <Modal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <Form
          {...layout}
          form={form}
          name="update"
          initialValues={initialValues}
          onFinish={handleUpdate}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item name="key" hidden initialValue={initialValues.id}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Feature"
            name="feature"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="token Needed"
            name="token_needed"
            rules={[{ required: true }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item label="Type" name="type" rules={[{ required: true }]}>
            <Select>
              <Option value="job_posting">Job Posting</Option>
              <Option value="talent_profile_purchase">
                Talent profile purchase
              </Option>
              <Option value="corporate_logo_branding">
                Corporate logo branding
              </Option>
              <Option value="corporate_news_release">
                Corporate news release
              </Option>
              <Option value="emp_branding_article">
                Employer branding article
              </Option>
            </Select>
          </Form.Item>
          <Form.Item label="Additional Info ENG" name="additional_info_eng" rules={[{ required: true }]}>
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item label="Simplified Chinese" name="additional_info_zh_ch" rules={[{ required: true }]}>
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item label="Traditional Chinese" name="additional_info_zh_cht" rules={[{ required: true }]}>
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Form
          {...layout}
          form={form}
          name="create"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item name="key" hidden initialValue={initialValues.id}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Feature"
            name="feature"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="token Needed"
            name="token_needed"
            rules={[{ required: true }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item label="Type" name="type" rules={[{ required: true }]}>
            <Select>
              <Option value="job_posting">Job Posting</Option>
              <Option value="talent_profile_purchase">
                Talent Profile Purchase
              </Option>
              <Option value="corporate_logo_branding">
                Corporate Logo Branding
              </Option>
              <Option value="corporate_news_release">
                Corporate News Release
              </Option>
              <Option value="emp_branding_article">
                Employer Branding Article
              </Option>
            </Select>
          </Form.Item>
          <Form.Item label="Description ENG" name="additional_info_eng" rules={[{ required: true }]}>
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item label="Simplified Chinese" name="additional_info_zh_ch" rules={[{ required: true }]}>
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item label="Traditional Chinese" name="additional_info_zh_cht" rules={[{ required: true }]}>
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SubscriptionTable;
