import SubscriptionTable from "../../components/common/subscriptionTable";
import "./membership-plans.css";

export default function SubcriptionInfo() {
  return (
    <>
      <div className="plan-top-container">
        <h3>Subscription Information</h3>
      </div>
      <SubscriptionTable />
    </>
  );
}
