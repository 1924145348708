import InvoiceTable from '../../components/common/invoiceTable';

export default function PaymentApproval() {
  return (
    <>
      <div className="plan-top-container">
        <h3>Payment Approval</h3>
      </div>
      <InvoiceTable />
    </>
  )
}
