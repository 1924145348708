import React, {useEffect,useState} from 'react';
import { Form, Input, Button } from 'antd';
import { useSignIn, useIsAuthenticated } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

import {userLogin} from '../../services/Services';
import './styles.css';

const Login = () => {

  const [error, setError] = useState(null);
  const signIn = useSignIn();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const [type] = useState(localStorage.getItem("type"));

  useEffect(() => {
    if(isAuthenticated()){
      navigate('/dashboard/recruiters');
    }
  }, [isAuthenticated, navigate, type]);


  const onFinish = async (values) => {
    let request = {
      'email': values.username,
      'password': values.password,
    }
    try {
      const response = await userLogin(request);
      localStorage.setItem('type', response.data.data.type);
      signIn({
        token: response.data.data.token,
        expiresIn: 3600,
        tokenType: "Bearer",
        authState: { email: request.email },
      });
      navigate('/dashboard/recruiters');
    } catch (err) {
      setError('Invalid login credentials');
    }
  };

  return (
    <div className="login-container">
      <Form
        name="login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        className="login-form"
      >
        <h1 className="login-header">Login</h1>

        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input onChange={()=>(setError(''))} className="input-field" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password onChange={()=>(setError(''))}  className="input-field" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-button">
            Log in
          </Button>
          {error && <p className="error">{error}</p>}
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
