import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider,RequireAuth } from 'react-auth-kit'

import Dashboard from "./pages/dashboard";
import Login from "./pages/login/Login";
import NewPassword from "./pages/login/NewPassword";
import Admin from "./pages/users/Admin";
import Recruiters from "./pages/users/Recruiters";
import Freelancers from "./pages/users/Freelancers";
import Dropdown from "./pages/content/Dropdown";
import Branding from "./pages/content/Branding";
import MembershipPlans from "./pages/subcriptions/MembershipPlans";
import SubcriptionInfo from "./pages/subcriptions/SubcriptionInfo";
import PaymentApproval from "./pages/payments/PaymentApproval";
import ServiceInvoices from "./pages/payments/ServiceInvoices";
import NotFound from './pages/404';

function App() {
  return (
    <AuthProvider authType = {'cookie'}
      authName={'_auth'}
      cookieDomain={window.location.hostname}
      cookieSecure={false}>
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/new-password/:id" element={<NewPassword />} />
            <Route path="/dashboard" element={<RequireAuth loginPath="/"><Dashboard /></RequireAuth>}>
              <Route path="admin" element={<Admin />} />
              <Route path="recruiters" element={<Recruiters />} />
              <Route path="freelancers" element={<Freelancers />} />
              <Route path="dropdown" element={<Dropdown />} />
              <Route path="branding" element={<Branding />} />
              <Route path="membership-plans" element={<MembershipPlans />} />
              <Route path="subcription-info" element={<SubcriptionInfo />} />
              <Route path="payment-approval" element={<PaymentApproval />} />
              <Route path="service-invoices" element={<ServiceInvoices />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
