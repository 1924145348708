import MembershipTable from "../../components/common/membersshipTable";
import "./membership-plans.css";

export default function MembershipPlans() {

  return (
    <>
      <div className="plan-top-container">
        <h3>Membership Plans</h3>
      </div>
      <MembershipTable />
    </>
  );
}
